<template>
  <div id="main">
    <v-app id="app">
      <v-navigation-drawer
        v-model="drawer"
        app
      >
        <v-list
          expand
          nav
        >
          <!-- ENTIRE list is wrapped in a template -->
          <template v-for="(item, i) in menuItems">
            <!-- use v-if to determine if 2nd level nesting is needed -->
            <!-- if it's a menu item with no children -->
            <v-list-item
              v-if="!item.children"
              :key="`subheader-${i}`"
              color="primary"
              :to="item.link"
            >
              <v-list-item-action>
                <v-badge
                  v-if="item.badge"
                  color="red"
                >
                  <span slot="badge">{{ item.badge }}</span>

                  <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- else if it has children -->
            <v-list-group
              v-else
              :key="`subheader-${i}`"
              :group="item.link"
              color="primary"
            >
              <!-- this template is for the title of top-level items with children -->
              <template #activator>
                <v-list-item-action>
                  <v-badge
                    v-if="item.badge"
                    color="red"
                  >
                    <span slot="badge">{{ item.badge }}</span>

                    <v-icon>{{ item.icon }}</v-icon>
                  </v-badge>
                  <v-icon v-else>
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item-content>
              </template>
              <!-- this template is for the children/sub-items (2nd level) -->
              <template v-for="(subItem, j) in item.children">
                <!-- another v-if to determine if there's a 3rd level -->
                <!-- if there is NOT a 3rd level -->
                <v-list-item
                  v-if="!subItem.children"
                  :key="`subheader-${j}`"
                  class="ml-5"
                  :to="item.link + subItem.link"
                >
                  <v-list-item-action>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ subItem.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- if there is a 3rd level -->
                <v-list-group
                  v-else
                  :key="`subheader-${j}`"
                  color="primary"
                  :group="subItem.link"
                  sub-group
                >
                  <template #activator>
                    <v-list-item-action>
                      <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ subItem.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item-content>
                  </template>
                  <template v-for="(subSubItem, k) in subItem.children">
                    <v-list-item
                      :key="`subheader-${k}`"
                      color="primary"
                      :value="true"
                      :to="item.link + subItem.link + subSubItem.link"
                    >
                      <v-list-item-action>
                        <v-icon>{{ subSubItem.icon }}</v-icon>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ subSubItem.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
            </v-list-group>
          </template>

          <!-- <v-list-item-group
            color="primary"
          >
            <v-list-item
              v-for="item in menuItems"
              :key="item.title"
              :to="item.link"
            >
              <v-list-item-action>
                <v-badge
                  v-if="item.badge"
                  color="red"
                >
                  <span slot="badge">{{ item.badge }}</span>

                  <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group> -->
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
        color="primary"
        dark
        app
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title class="white--text">
          {{ appName }}
        </v-toolbar-title>
        <v-spacer />
        <v-avatar
          color="grey lighten-2"
          class="mx-2"
          size="36"
        >
          <v-icon dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
        <span class="white--text">
          {{ userFullName }}
        </span>
        <v-btn
          icon
          @click="logout"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <main>
          <router-view />
        </main>
      </v-main>

      <v-footer
        color="primary"
        app
        inset
      >
        <span class="white--text">
          &nbsp;&nbsp;&nbsp;&nbsp; &copy; {{ new Date().getFullYear() }}
        </span>
        <v-spacer />
        <span class="white--text">
          Created by TOT Central Region
        </span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'Main',
  components: {},
  props: {
    // source: String,
  },
  data() {
    return {
      drawer: null,
      appName: 'TOT Operation Platform',
      menuItems: [
        // { icon: 'mdi-home', title: 'Home', link: '/home' },
        { icon: 'mdi-view-dashboard', title: 'Dashboard', link: '/dashboard' },
        { icon: 'mdi-switch', title: 'OLT', link: '/olt' },
        { icon: 'mdi-router-wireless', title: 'ONU', link: '/onu' },
        {
          icon: 'mdi-tools',
          title: 'ONU Config',
          link: '/onuconfig',
          children: [
            // {
            //   icon: 'mdi-tools',
            //   title: 'ZTE',
            //   link: '/zteonuconfig',
            //   children: [
            //     { icon: 'mdi-tools', title: 'C300', link: '/C300' },
            //     { icon: 'mdi-tools', title: 'C600', link: '/C600' },
            //   ],
            // },
            {
              title: 'ZTE', link: '/zteonuconfig',
            },
            {
              title: 'Huawei', link: '/huaweionuconfig',
            },
            {
              title: 'FiberHome', link: '/fiberhomeonuconfig',
            },
          ],
        },
        {
          icon: 'mdi-tools',
          title: 'OLT Config',
          link: '/oltconfig',
          children: [
            // {
            //   icon: 'mdi-tools',
            //   title: 'ZTE',
            //   link: '/zteonuconfig',
            //   children: [
            //     { icon: 'mdi-tools', title: 'C300', link: '/C300' },
            //     { icon: 'mdi-tools', title: 'C600', link: '/C600' },
            //   ],
            // },
            {
              title: 'ZTE', link: '/zteoltconfig',
            },
            {
              title: 'Huawei', link: '/huaweioltconfig',
            },
            {
              title: 'FiberHome', link: '/fiberhomeoltconfig',
            },
          ],
        },
        { icon: 'mdi-email', title: 'Contact', link: '/contact' },
      ],
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    userFullName() {
      return this.$store.getters.userFullName
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.go('/login')
      })
    },
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
